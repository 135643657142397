import React from "react";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import PropTypes from "prop-types";
import SectionWrapper, { SectionHeader } from "./service.style";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const Services = ({ secJudul, sectionImage }) => {
  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Text
            content={<FormattedMessage id="fasilitasProdukJud" />}
            {...secJudul}
          />
          <Text
            content={<FormattedMessage id="fasilitasProdukDesc1" />}
          />
          <Text content={<FormattedMessage id="fasilitasProdukDesc2" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc3" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc4" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc5" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc6" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc7" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc8" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc9" />}/>
          <Text content={<FormattedMessage id="fasilitasProdukDesc10" />}/>
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Services.prototype = {
  secText: PropTypes.object,
  sectionImage: PropTypes.object,
  secJudul: PropTypes.object,
};

Services.defaultProps = {
  secJudul: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: ["30px","34px"],
    letterSpacing: "0.15em",
    fontWeight: "500",
    color: "#000000",
    mb: "30px",
    mt: "60px",
  },
  sectionImage: {
    width: "100%",
  },
};

export default injectIntl(Services);
