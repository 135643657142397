import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import Image from "gatsby-image";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import SectionWrapper, { SectionHeader } from "./service.style";

const Services = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image/test1/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // const testList = JSON.parse(intl.formatMessage({ id: "looptest" }));
  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Image
            className="image1"
            fluid={
              (data.image1.childImageSharp.fluid !== null) | undefined
                ? data.image1.childImageSharp.fluid
                : {}
            }
            objectFit="contain"
            alt="Feature Image 1"
          />
          <Text content={<FormattedMessage id="agrovetaDesc" />} />
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

export default injectIntl(Services);
