import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { ResetCSS } from "../assets/css/style";
import Navbar from "../containers/reusableItem/Navbar";
import Agroveta from "../containers/AboutUs/Agroveta/indexb";
import Prinsipal from "../containers/reusableItem/Prinsipal";
import Prinsipal1 from "../containers/reusableItem/Prinsipal/indexb";
import BidangBisnis from "../containers/AboutUs/BidangBisnis";
import SumberDayaManusia from "../containers/AboutUs/SumberDayaManusia";
import FasilitasProduk from "../containers/AboutUs/FasilitasProduk";
import FasilitasProduk1 from "../containers/AboutUs/FasilitasProduk/indexb";
import QualityControl from "../containers/AboutUs/QualityControl";
import QualityControl1 from "../containers/AboutUs/QualityControl/indexb";
import VisiMisi from "../containers/AboutUs/VisiMisi/index";
import NilaiperusahaanSection from "../containers/AboutUs/Nilaiperusahaan";
import RisetDanPengembangan from "../containers/AboutUs/RisetDanPengembangan";
import RisetDanPengembangan1 from "../containers/AboutUs/RisetDanPengembangan/indexb";
import Banner from "../containers/reusableItem/reusableBanner/background";
import BannerContent from "../containers/reusableItem/reusableBanner/bannerContent";
import Logistik from "../containers/AboutUs/Logistik";
import Logistik1 from "../containers/AboutUs/Logistik/indexb";
import Footer from "../containers/reusableItem/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/seo";
import { GlobalStyle } from "../containers/reusableItem/agency.style";
import { agencyTheme } from "../theme/agency";
import { AgencyWrapper } from "../containers/reusableItem/agency.style";
import { Modal } from "@redq/reuse-modal";
import { useIntl } from "gatsby-plugin-intl";

export default () => {
  const intl = useIntl();
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title={intl.formatMessage({ id: "titleAboutUsPage" })} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent
              title={intl.formatMessage({ id: "titleAboutUsPage" })}
            />
          </Banner>
          <Agroveta />
          <a id="prinsipal">
            <Prinsipal />
          </a>
         
          <Prinsipal1 />
          <a id="bidangbisnis">
            <BidangBisnis />
          </a>
          <a id="visimisi">
            <VisiMisi />
          </a>
          <a id="nilai">
            <NilaiperusahaanSection />
          </a>
          <a id="sumberdaya">
            <SumberDayaManusia />
          </a>
          <RisetDanPengembangan />
          <RisetDanPengembangan1 />
          <a id="fasilitas">
            <FasilitasProduk />
          </a>
          <FasilitasProduk1 />
          <QualityControl />
          <QualityControl1 />
          <Logistik />
          <Logistik1 />
          <Footer />
        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>

    // <ThemeProvider theme={hostingTheme}>
    //   <ParallaxProvider>
    //     <SEO title="About Us" />

    //     <ResetCSS />
    //     <GlobalStyle />

    //     <ContentWrapper>
    //       <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
    //         <DrawerProvider>
    //           <Navbar />
    //         </DrawerProvider>
    //       </Sticky>

    //       <Banner>
    //         <BannerContent title="Tentang Kami" />
    //       </Banner>
    //       {/* <FeatureSection /> */}
    // <Agroveta />
    // <Prinsipal />
    // <Prinsipal1 />
    // <BidangBisnis />
    // <VisiMisi />
    // <NilaiperusahaanSection />
    // <SumberDayaManusia />
    // <RisetDanPengembangan />
    // <RisetDanPengembangan1 />
    // <FasilitasProduk />
    // <FasilitasProduk1 />
    // <QualityControl />
    // <QualityControl1 />
    // <Logistik />
    // <Logistik1 />
    // <Footer />
    //     </ContentWrapper>
    //   </ParallaxProvider>
    // </ThemeProvider>
  );
};
