import React from "react";
import PropTypes from "prop-types";
import Image from "../../../reusecore/Image";
import Text from "../../../reusecore/Text";
import Heading from "../../../reusecore/Heading";
import Container from "../../../reusecore/UI/Container";
import { ScalableWrapper } from "./scalable.style";
import ScalableImage from "../../../assets/image/test1/Logo--Kalbe-Panca-Sradha 1.png";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const ScalableHistory = ({
  row,
  col,
  title,
  description,
  sectionSubTitle,
  cardArea,
  sectionSubTitle1,
}) => {
  return (
    <ScalableWrapper id="scalable">
      <Container noGutter mobileGutter>
        {/* <Box className="row" {...row}> */}
        {/* <Box className="colleft" {...col} style={{ flexDirection: "column" }}> */}
        <Text
          content={<FormattedMessage id="nilaiPerusahaanJud" />}
          {...sectionSubTitle1}
        />
        <br />
        {/* <Text content="Kalbe Panca Sradha" {...sectionSubTitle} /> */}

        <Image
          src={ScalableImage}
          className="ScalableImage gambar"
          alt="Scalable Section Image"
        />

        <Heading
          content={<FormattedMessage id="nilaiPerusahaanSubJud1" />}
          {...title}
        />
        <Text
          content={<FormattedMessage id="nilaiPerusahaanDesc1" />}
          {...description}
        />

        <Heading
          content={<FormattedMessage id="nilaiPerusahaanSubJud2" />}
          {...title}
        />
        <Text
          content={<FormattedMessage id="nilaiPerusahaanDesc2" />}
          {...description}
        />

        <Heading
          content={<FormattedMessage id="nilaiPerusahaanSubJud3" />}
          {...title}
        />
        <Text
          content={<FormattedMessage id="nilaiPerusahaanDesc3" />}
          {...description}
        />

        <Heading
          content={<FormattedMessage id="nilaiPerusahaanSubJud4" />}
          {...title}
        />
        <Text
          content={<FormattedMessage id="nilaiPerusahaanDesc4" />}
          {...description}
        />

        <Heading
          content={<FormattedMessage id="nilaiPerusahaanSubJud5" />}
          {...title}
        />
        <Text
          content={<FormattedMessage id="nilaiPerusahaanDesc5" />}
          {...description}
        />

        {/* <Box className="colright" {...col} {...cardArea}>
              <Image
                src={ScalableImage}
                className="ScalableImage"
                alt="Scalable Section Image"
              />
            </Box> */}
        {/* </Box> */}
        {/* </Box> */}
      </Container>
    </ScalableWrapper>
  );
};

// Transactions style props
ScalableHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Scalable default style
ScalableHistory.defaultProps = {
  // Scalable section row default style
  row: {
    flexBox: true,
    // flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Scalable section col default style
  col: {
    pr: "15px",
    pl: "15px",
    // width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    width: [1, 1, 1, 1, 1],
    flexBox: true,
    alignSelf: "center",
  },
  // Scalable section title default style
  title: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "700",
    color: "#000000",
    letterSpacing: "-0.010em",
    mb: "5px",
    maxWidth: ["100%", "100%", "100%", "100%", "100%"],
    ml: "auto",
    mr: "auto",
    textAlign: "center",
  },
  // Scalable section description default style
  description: {
    fontSize: "22px",
    fontWeight: "400",
    color: "#000000",
    lineHeight: "28px",
    mb: "40px",
    maxWidth: ["100%", "100%", "100%", "100%", "100%"],
    ml: "auto",
    mr: "auto",
    textAlign: "center",
  },
  sectionSubTitle: {
    fontSize: "34px!important",
    fontWeight: "bold",
    color: "#000000",
    lineHeight: "28px",
    // mb: "60px",
    maxWidth: ["100%", "100%", "100%", "100%", "430px"],
    ml: "auto",
    mr: "auto",
    mb: "2rem",
    textAlign: "center",
    letterSpacing: "0.15em",
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["1", "1", "1", "1", "1"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px",
  },
  // Scalable section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px",
  },
  sectionSubTitle1: {
    // as: "span",
    // textAlign: "center",
    // fontSize: "26px",
    // letterSpacing: "0.15em",
    // fontWeight: "700",
    // color: "#84BD00",
    // mb: "5px",
    fontSize: "26px!important",
    fontWeight: "700",
    color: "#84BD00",
    lineHeight: "28px",
    // mb: "60px",
    maxWidth: ["100%", "100%", "100%", "100%", "430px"],
    ml: "auto",
    mr: "auto",
    mb: "1px",
    textAlign: "center",
    letterSpacing: "0.15em",
  },
};

export default injectIntl(ScalableHistory);
