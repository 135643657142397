import React from "react";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import PropTypes from "prop-types";

import SectionWrapper, { SectionHeader } from "./service.style";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const Services = ({ secJudul, sectionImage }) => {
  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Text
            content={<FormattedMessage id="qualityControlJud" />}
            {...secJudul}
          />
          <Text content={<FormattedMessage id="qualityControlDesc1" />} />
          <Text content={<FormattedMessage id="qualityControlDesc2" />} />
          <Text content={<FormattedMessage id="qualityControlDesc3" />} />
          <Text content={<FormattedMessage id="qualityControlDesc4" />} />
          <Text content={<FormattedMessage id="qualityControlDesc5" />} />
          <Text content={<FormattedMessage id="qualityControlDesc6" />} />
          <Text content={<FormattedMessage id="qualityControlDesc7" />} />
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Services.prototype = {
  secText: PropTypes.object,
  sectionImage: PropTypes.object,
  secJudul: PropTypes.object,
};

Services.defaultProps = {
  secJudul: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: ["30px", "34px"],
    letterSpacing: "0.15em",
    fontWeight: "500",
    color: "#000000",
    mb: "30px",
    mt: "60px",
  },
  sectionImage: {
    width: "100%",
  },
};

export default injectIntl(Services);
