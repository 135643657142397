import React from "react";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import PropTypes from "prop-types";

import SectionWrapper, { SectionHeader } from "./service.style";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const Services = ({ sectionSubTitle, secJudul }) => {
  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Text
            content={<FormattedMessage id="visimisiJud" />}
            {...sectionSubTitle}
          />
          <Text
            content={<FormattedMessage id="visimisiSubJud1" />}
            {...secJudul}
          />
          <Text content={<FormattedMessage id="visimisiDesc1" />} />
          <Text
            content={<FormattedMessage id="visimisiSubJud2" />}
            {...secJudul}
          />
          <Text content={<FormattedMessage id="visimisiDesc2" />} />
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Services.prototype = {
  secText: PropTypes.object,
  sectionImage: PropTypes.object,
  secJudul: PropTypes.object,
};

Services.defaultProps = {
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "60px",
  },
  secJudul: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "24px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#000000",
    mb: "20px",
  },
};

export default injectIntl(Services);
