import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import Fade from "react-reveal/Fade";
import Box from "../../../reusecore/Box";
import Text from "../../../reusecore/Text";
import Heading from "../../../reusecore/Heading";
import Card from "../../../reusecore/Card";
import FeatureBlock from "../../../reusecore/FeatureBlock";
import AboutUsSectionWrapper from "./aboutUsSection.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const AboutUsSection = ({ row, col, title, description, textArea }) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image/new/Bisnis/ayam.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "image/new/Bisnis/anjing.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "image/new/Bisnis/udang.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Image
                  fluid={
                    (Data.image1 !== null) | undefined
                      ? Data.image1.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 1"
                />
              </Fade>
              <Fade left delay={60}>
                <Image
                  fluid={
                    (Data.image3 !== null) | undefined
                      ? Data.image3.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 3"
                />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <Image
                  fluid={
                    (Data.image2 !== null) | undefined
                      ? Data.image2.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 2"
                />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content={<FormattedMessage id="bidangBisnisJud" />}
                  {...title}
                />
              }
              description={
                <Text
                  content={<FormattedMessage id="bidangBisnisDesc" />}
                  {...description}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // About us section col default style
  col: {
    width: [1, "100%", "50%"],
  },
  // About us section text area default style
  textArea: {
    maxWidth: "490px",
    pl: ["10px","40px"],
    pr: ["10px","0px"],
  },
  // About us section title default style
  title: {
    // fontSize: "34px",
    // lineHeight: "1.5",
    // fontWeight: "400",
    // color: "#0f2137",
    // letterSpacing: "-0.025em",
    // mb: "30px",
    as: "span",
    display: "block",
    textAlign: "left",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "10px",
  },
  // About us section description default style
  description: {
    mt: "40px",
    fontSize: "22px",
    color: "#000000",
    lineHeight: "1.75",
    mb: "33px",
  },

  // feature title default style
  featureTitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#343d48",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.020em",
  },
  // Button default style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
  },
};

export default injectIntl(AboutUsSection);
