import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "../../../reusecore/UI/Container";
import Text from "../../../reusecore/Text";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Button from "../../../reusecore/Button";

import SectionWrapper, { SectionHeader } from "./service.style";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import { useIntl } from "gatsby-plugin-intl";

const Services = ({
  sectionSubTitle,
  sectionImage,
  btnStyle,
  ContentStyle,
}) => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image/new/SDM.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const intl = useIntl();

  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        <SectionHeader>
          <Text
            content={<FormattedMessage id="sumberDayaManusiaJud" />}
            {...sectionSubTitle}
          />
          <Image
            className="imagesdm"
            {...sectionImage}
            fluid={
              (data.image1.childImageSharp.fluid !== null) | undefined
                ? data.image1.childImageSharp.fluid
                : {}
            }
            objectFit="contain"
            alt="Feature Image 1"
          />
          <Text
            className="asdasd"
            content={<FormattedMessage id="sumberDayaManusiaDesc1" />}
          />
          <Text content={<FormattedMessage id="sumberDayaManusiaDesc2" />} />
          <Text content={<FormattedMessage id="sumberDayaManusiaDesc3" />} />
          <a href="/ContactUs">
            <Button title={intl.formatMessage({ id: "joinus" })} {...btnStyle} />
          </a>
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

Services.prototype = {
  secText: PropTypes.object,
  sectionImage: PropTypes.object,
  btnStyle: PropTypes.object,
};

Services.defaultProps = {
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "30px",
  },
  sectionImage: {
    width: "80%",
    ml: "auto",
    mr: "auto",
  },
  btnStyle: {
    mt: "20px",
    backgroundColor: "#84BD00",
    width: "[100%, 20rem]",
    borderRadius: "15px",
  },
  ContentStyle: {
    width: "76%",
  },
};

export default injectIntl(Services);
